export default function sectionTile({ children }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        <h1 className="text-yellow font-serif text-4xl font-medium md:text-6xl">{children}</h1>
        <div className="absolute -left-6 -top-3 hidden w-0 h-12 border-r-2 border-yellow transform rotate-12 md:block md:h-20"></div>
      </div>
    </div>
  )
}
