import Image from 'next/image'
import Link from 'next/link'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

import { getAllPosts } from '../../lib/api'

import Navbar from 'components/navbar'
import Layout from 'components/layout'
import Seo from 'components/seo'
import Pagetitle from 'components/pageTitle'
import Footer from 'components/footer'
import Articlecard from 'components/article/card'

dayjs.locale('fr')

export default function blog({ posts }) {
  const otherArticles = [...posts?.nodes]
  const lastArticle = otherArticles.shift()
  return (
    <>
      <Seo
        title="Le blog"
        description="Conseils gratuits d'experts du marketing digital pour lancer votre stratégie digitale. Découvrez tous  les secrets des réseaux sociaux !"
        canonical="/blog"
      />
      <div className="overflow-hidden">
        <Navbar />
        <main className="relative mt-12 md:mt-24">
          <Layout>
            <Pagetitle>Le blog</Pagetitle>

            <div className="mt-12 md:mt-24">
              <Link href={`/blog/${lastArticle.slug}`}>
                <a>
                  <article
                    className={`relative grid gap-12 grid-cols-1 p-8 w-full bg-yellow-50 ${
                      lastArticle.featuredImage ? 'md:grid-cols-2' : 'md:grid-cols-1'
                    } md:p-16`}>
                    <div>
                      <h2 className="font-serif text-2xl font-bold md:text-4xl">{lastArticle.title}</h2>

                      <div
                        className="mt-4 text-justify md:text-lg"
                        dangerouslySetInnerHTML={{ __html: lastArticle.excerpt }}
                      />
                    </div>
                    {lastArticle.featuredImage && (
                      <div>
                        <div className="relative w-full overflow-hidden" style={{ paddingTop: '100%' }}>
                          <Image
                            layout="fill"
                            src={`${lastArticle.featuredImage.node.guid}`}
                            alt={`${lastArticle.featuredImage.node.altText}`}
                            className="absolute z-10 object-cover"
                          />
                          <div className="absolute z-0 inset-0 w-full h-full bg-gray-300 object-cover animate-pulse"></div>
                        </div>
                      </div>
                    )}

                    <div className="absolute right-0 top-1/2 hidden md:block">
                      <div
                        aria-hidden="true"
                        className="absolute -left-8 -top-8 inline-flex items-center justify-center w-16 h-16 text-orange border border-orange rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 34 34"
                          className="w-8 h-8"
                          stroke="currentColor">
                          <path strokeWidth={1.5} d="M32 17H17M17 17H2M17 17V2M17 17V32.5" />
                        </svg>
                      </div>
                    </div>
                  </article>
                </a>
              </Link>
            </div>

            <div className="grid gap-4 grid-cols-1 mt-16 md:grid-cols-3">
              {otherArticles.map((thisArticle) => (
                <Articlecard
                  key={thisArticle.id}
                  slug={thisArticle.slug}
                  featuredImage={thisArticle.featuredImage}
                  title={thisArticle.title}
                  excerpt={thisArticle.excerpt}
                />
              ))}
            </div>
          </Layout>
        </main>
        <div className="mt-32">
          <Footer />
        </div>
      </div>
    </>
  )
}

export async function getStaticProps() {
  const posts = await getAllPosts()
  return {
    props: { posts },
    revalidate: 600 // 10 min
  }
}
